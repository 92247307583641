<template>
    <ORowContainer>
        <div v-if="!props.workflows.current.ExternalID">
            <div class="dropdown">
                <button class="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown" @click="authenticate">
                    <div class="spinner-border spinner-border-sm me-2" role="status" v-if="creating">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <span>{{ $t("Send To Omega") }}</span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li v-if="dsDatabaseSetup.current.FeatureProcess_ID">
                        <button class="dropdown-item" @click="sendToOmega(dsDatabaseSetup.current.FeatureProcess_ID)">
                            <span><b>{{ $t("Feature Request") }}</b> <small>{{ $t("Help us improve Omega 365") }}</small></span>
                        </button>
                    </li>
                    <li v-if="dsDatabaseSetup.current.MissingFeatureProcess_ID">
                        <button class="dropdown-item" @click="sendToOmega(dsDatabaseSetup.current.MissingFeatureProcess_ID)">
                            <span><b>{{ $t("Missing Feature") }}</b> <small>{{ $t("Feature is required to get the job done") }}</small></span>
                        </button>
                    </li>
                    <li v-if="dsDatabaseSetup.current.BugProcess_ID">
                        <button class="dropdown-item" @click="sendToOmega(dsDatabaseSetup.current.BugProcess_ID)">
                            <span><b>{{ $t("Bug Report") }}</b> <small>{{ $t("Something went wrong") }}</small></span>
                        </button>
                    </li> 
                    <li v-if="dsDatabaseSetup.current.SupportRequestProcess_ID">
                        <button class="dropdown-item" @click="sendToOmega(dsDatabaseSetup.current.SupportRequestProcess_ID)">
                            <span><b>{{ $t("Support Request") }}</b> <small>{{ $t("Request Support") }}</small></span>
                        </button>
                    </li> 
                </ul>
            </div>
        </div>
        <div v-else>
            <a class="fw-bold" :href="`${origin}/scope-workflow?ID=${props.workflows.current.ExternalID}`" target="_blank">{{ $t("Go To Omega Workflow") }} <i class="bi bi-box-arrow-up-right"></i></a>
            <!-- <button class="ms-2 fw-bold btn btn-sm btn-link" @click="getStatus()">{{ $t("Get Status From Omega Workflow") }}</button> -->
        </div>
    </ORowContainer>
</template>

<script setup>
    import { ref, defineProps, onMounted } from 'vue';
    import { API } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject';

    const creating = ref(false);
    const authenticated = ref(false);

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        workflows: Object
    });

    const dsDatabaseSetup = getOrCreateDataObject({
        "id": "dsDatabaseSetup_OmegaScopeItem",
        "viewName": "stbv_Database_Setup",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "BugProcess_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "FeatureProcess_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "MissingFeatureProcess_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "SupportUrl",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "FeedbackOrgUnit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "FeedbackUrl",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "SupportRequestProcess_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    })

    const origin = "https://omega.omega365.com";

    function authenticate(){
        creating.value = false;
        window.open(`${origin}/scope-jwt?host=${encodeURIComponent(location.host)}`);
    }

    async function getStatus(){
        if(authenticated.value == false){
            authenticate();
            return;
        }
        const response = await API.requestPost("/api/scope/getstatus", {
            omegaId: props.workflows.current.ExternalID,
            id: props.workflows.current.ID,
            propertyName: "SondreTest",
            origin: origin
        });
    }

    async function sendToOmega(processId){
        if(authenticated.value == false){
            authenticate();
            return;
        }
        creating.value = true;
        const response = await API.requestPost("/api/scope/createScopeItem", {
            id: props.workflows.current.ID,
            title: props.workflows.current.Title,
            description: props.workflows.current.Description,
            orgUnitId: dsDatabaseSetup.current.FeedbackOrgUnit_ID, 
            processId,
            origin: origin
        });
       
        var scopeItemId = response.id;

        props.workflows.current.ExternalID = scopeItemId;
        await props.workflows.save();

        creating.value = false;
    }

    async function sendToken(data){
        await fetch("/api/scope/storeandsendtoken", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                tokenResponse: JSON.parse(data),
                origin: origin
            })
        });
    }
 
    onMounted(() => {
        dsDatabaseSetup.load();
        window.addEventListener("message", async event => {
            authenticated.value = true;
            await sendToken(event.data);
        });
    })

</script>